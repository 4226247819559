import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading = new BehaviorSubject(false);
  private requisicoes: HttpRequest<any>[] = [];

  constructor() {}

  removerRequisicoes(req: HttpRequest<any>) {
    const i = this.requisicoes.indexOf(req);
    if (i >= 0) {
      this.requisicoes.splice(i, 1);
    }
    setTimeout(() => {
      this.isLoading.next(this.requisicoes.length > 0);
    }, 0);
  }

  adicionarRequisicoes(req: HttpRequest<any>) {
    this.requisicoes.push(req);
    setTimeout(() => {
      this.isLoading.next(true);
    }, 0);
  }
}
